import React from "react";

import DiscordIcon from "../../../res/imgs/logo/discord/discord_1.svg";
import OpenSeaIcon from "../../../res/imgs/logo/opensea/opensea_1.svg";

export default () => {
    return (
        <div className="flex items-center space-x-5 text-theme-red">
            <a
                href="https://discord.gg/yS75j4zbqP"
                target="_blank"
                className="group flex h-12 w-12 items-center justify-center rounded-full bg-theme-red transition hover:bg-white"
            >
                <DiscordIcon className="h-7 fill-white transition group-hover:fill-theme-red"/>
            </a>

            <a
                href="https://opensea.io/"
                target="_blank"
                className="group flex h-12 w-12 items-center justify-center rounded-full bg-theme-red transition hover:bg-white"
            >
                <OpenSeaIcon className="h-7 fill-white transition group-hover:fill-theme-red"/>
            </a>
        </div>
    );
};
