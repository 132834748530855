import "./index.css";
import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {gsap} from "gsap";

import DesktopNavigationBar from "./component/NavigationBar/DesktopNavigationBar.mjs";
import MobileNavigationBar from "./component/NavigationBar/MobileNavigationBar.mjs";
import Mint from "./component/Mint/Mint.mjs";
import Footer from "./component/Footer/Footer.mjs";
import { ToastContainer } from "react-toastify";

const App = () => {
    useEffect(() => {
        gsap.from(".test", {
            y: 100,
            opacity: 0,
        });
    });

    return (
        <>
            <header className="bg-theme-black">
                <div className="md:hidden">
                    <MobileNavigationBar/>
                </div>
                <div className="hidden md:block">
                    <DesktopNavigationBar/>
                </div>
            </header>
            <main>
                <Mint/>
            </main>
            <footer>
                <Footer/>
            </footer>
            <ToastContainer position="top-right" newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss autoClose={5000} draggable/>
        </>
    );
};

ReactDOM.render(<App/>, document.getElementById("root"));
