import React, { useState } from "react";

import MintButton from "./MintButton.mjs";
import CandyMan from "../../../res/imgs/candy_mans/2.png";

export default () => {
    const [amountOfWhitelist, setAmountOfWhitelist] = useState(0);
    const [amountOfNormal, setAmountOfNormal] = useState(0);
    const [maximumMintOfWhitelist, setMaximumMintOfWhitelist] = useState(5);

    const range = (size, startAt = 0) => {
        return [...Array(size).keys()].map((i) => i + startAt);
    };

    const priceTable = () => (
        <>
            <div className="flex min-w-full justify-between rounded-lg bg-white/80 p-3 shadow-md shadow-theme-red-shadow/70">
                <p className="font-share-techmono text-3xl">0.08 ETH</p>
                <p className="font-jf-openhuninn text-3xl">公售</p>
            </div>

            <div className="flex min-w-full justify-between rounded-lg bg-white/80 p-3 shadow-md shadow-theme-red-shadow/70">
                <p className="font-share-techmono text-3xl">0.06 ETH</p>
                <p className="font-jf-openhuninn text-3xl">白單</p>
            </div>
        </>
    );

    const updateMaximumMintOfWhitelist = (amount) => {
        setAmountOfWhitelist(0);
        setAmountOfNormal(0);
        setMaximumMintOfWhitelist(amount);
    }

    const setAmount = (type, amount) => () => {
        if (type === "whitelist") setAmountOfWhitelist(amount);
        else setAmountOfNormal(amount);
    };

    const renderAmountButton = (type) => {
        return range(type === "whitelist" ? 6 : 21, 0).map((amount) => {
            let baseClassName = "flex h-12 w-12 items-center justify-center rounded-lg text-2xl";
            let className = "bg-black/80 text-white lg:transition lg:hover:bg-white/80 lg:hover:text-black lg:hover:outline lg:hover:outline-1 lg:hover:outline-black";
            let clickHandler = setAmount(type, amount);

            if (type === "whitelist" && amount > maximumMintOfWhitelist) {
                className = "bg-black/40 text-white/20 cursor-default";
                clickHandler = null;
            }

            if (amount === (type === "whitelist" ? amountOfWhitelist : amountOfNormal)) {
                className = "bg-theme-red text-white cursor-default";
                clickHandler = null;
            }

            return (
                <button
                    key={type === "whitelist" ? `amount_of_whitelist_${amount.toString()}` : `amount_of_normal_${amount.toString()}`}
                    onClick={clickHandler}
                    className={baseClassName + " " + className}
                >
                    <samp>{amount}</samp>
                </button>
            );
        });
    };

    return (
        <div className="flex flex-col justify-center bg-theme-red px-3 py-8 md:py-16">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 place-items-center gap-3 md:grid-cols-2 xl:gap-0">
                    <div className="flex min-w-full flex-col gap-3 md:min-w-fit">
                        <div className="min-w-full rounded-lg border-solid border-white/95 bg-white/80 shadow-md shadow-theme-red-shadow/70 md:min-w-fit">
                            <div className="flex flex-col justify-center pt-5">
                                <img src={CandyMan} alt="" className="" />
                            </div>
                        </div>

                        <div className="hidden md:grid md:gap-3 lg:grid-cols-2 xl:hidden">{priceTable()}</div>
                    </div>

                    <div className="grid min-w-full grid-cols-1 gap-3 lg:grid-cols-2">
                        <div className="grid grid-cols-1 gap-3 md:hidden lg:col-span-2 xl:grid xl:grid-cols-2">{priceTable()}</div>

                        <div className="min-w-full rounded-lg bg-white/80 p-3 shadow-md shadow-theme-red-shadow/70 lg:col-span-2">
                            <div className="flex items-center justify-between">
                                <p className="font-jf-openhuninn text-2xl">以白單價格 MINT</p>
                                <p className="font-share-techmono text-2xl">{(0.06 * amountOfWhitelist).toFixed(2)} ETH</p>
                            </div>

                            <div className="my-4 flex justify-center gap-3">{renderAmountButton("whitelist")}</div>

                            <div className="flex justify-end">
                                <p className="text-sm">每個擁有白單的錢包地址 MINT 上限 5 隻</p>
                            </div>
                        </div>
                        <div className="min-w-full rounded-lg bg-white/80 p-3 shadow-md shadow-theme-red-shadow/70 lg:col-span-2">
                            <div className="flex items-center justify-between">
                                <p className="font-jf-openhuninn text-2xl">以公售價格 MINT</p>
                                <p className="font-share-techmono text-2xl">{(0.08 * amountOfNormal).toFixed(2)} ETH</p>
                            </div>

                            <div className="my-4 flex flex-wrap justify-center gap-3">{renderAmountButton("normal")}</div>
                        </div>

                        <MintButton amountOfWhitelist={amountOfWhitelist} amountOfNormal={amountOfNormal} updateMaximumMintOfWhitelist={updateMaximumMintOfWhitelist} />
                    </div>
                </div>
            </div>
        </div>
    );
};
