import React from "react";

import CommunityLinks from "./CommunityLinks.mjs";

import LogoIcon from "../../../res/imgs/logo/candy_man/logo_icon.svg";
import LogoWord from "../../../res/imgs/logo/candy_man/logo_word.svg";

export default () => {
    return (
        <div className="container mx-auto bg-theme-black px-6">
            <nav className="flex items-center justify-between">
                <div className="space-x-10">
                    <div className="inline-block space-x-3">
                        <a href="https://www.candymanstudio.com/" target="_self">
                            <LogoIcon className="inline-block h-14"/>
                        </a>
                        <a href="https://www.candymanstudio.com/" target="_self">
                            <LogoWord className="inline-block h-14"/>
                        </a>
                    </div>
                    <a
                        href="https://www.candymanstudio.com/#comp-kvs6faom1"
                        target="_self"
                        className="inline-block py-8 tracking-widest text-white transition hover:text-theme-red"
                    >
                        Resident
                    </a>
                    <a
                        href="https://www.candymanstudio.com/#comp-kzfxubzg"
                        target="_self"
                        className="inline-block py-8 tracking-widest text-white transition hover:text-theme-red"
                    >
                        About
                    </a>
                    <a
                        href="https://www.candymanstudio.com/#comp-kzfy05s2"
                        target="_self"
                        className="inline-block py-8 tracking-widest text-white transition hover:text-theme-red"
                    >
                        Roadmap
                    </a>
                    <a
                        href="#"
                        target="_self"
                        className="inline-block py-8 tracking-widest text-white transition hover:text-theme-red"
                    >
                        Mint
                    </a>
                </div>
                <CommunityLinks/>
            </nav>
        </div>
    );
};
