import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Interface } from "ethers/lib/utils";
import { BigNumber, utils } from "ethers";
import OpenLinkIcon from "../../../res/imgs/open_link.svg";

export default ({ amountOfWhitelist, amountOfNormal, updateMaximumMintOfWhitelist }) => {
  const totalPrice = 0.06 * amountOfWhitelist + 0.08 * amountOfNormal,
    specifiedChainId = "0x4",
    contractAddress = "0x6d1eF5C36B248e0eadd67BCF9f92bf794A4Fe015";
  const [ethereumState, setEthereumState] = useState({
    account: undefined,
    isWalletConnected: false,
    isWhitelisted: false,
    maximumMintOfWhitelist: 0,
    isAllowMint: false,
    errorMsg: ""
  });
  const [txHash, setTxHash] = useState(null);
  const [abiInterface] = useState(new Interface([
    "function mintCandyMan(uint256 normal, uint256 whitelisted)",
    "function isWhitelisted(address buyer) public view returns (bool)",
    "function getWhitelistedBuyTotalByAddress(address buyer) public view returns (uint256)"
  ]));

  const process = async (mint = false) => {
    console.log("process");
    let checkingResult = {
      account: undefined,
      isWalletConnected: false,
      isWhitelisted: false,
      isAllowMint: false,
      maximumMintOfWhitelist: 0,
      errorMsg: ""
    };

    try {
      let account = await window.ethereum.request({ method: "eth_accounts" });
      if (account && account.length > 0) {
        account = account[0];
        if (!mint) toast.success(`成功連接 ${account.substring(0, 7)} 錢包！`);
        checkingResult.isWalletConnected = true;
        checkingResult.account = account.toString();
      } else {
        toast.error("請連接錢包！");
        checkingResult.errorMsg = "請連接錢包！";
        setEthereumState(checkingResult);
        return;
      }
    } catch (e) {
      toast.error("請連接錢包！");
      checkingResult.errorMsg = "請連接錢包！";
      setEthereumState(checkingResult);
      console.error(e);
      return;
    }

    try {
      let chainId = await window.ethereum.request({ method: "eth_chainId" });

      if (chainId !== specifiedChainId) {
        toast.error("請切換至以太坊主網！");
        checkingResult.errorMsg = "請切換至以太坊主網！";
        setEthereumState(checkingResult);
        return;
      }
    } catch (e) {
      toast.error("無法取得目前使用的以太坊網路！");
      checkingResult.errorMsg = "無法取得目前使用的以太坊網路！";
      setEthereumState(checkingResult);
      console.error(e);
      return;
    }

    try {
      let whitelisted = (
        await window.ethereum.request({
          method: "eth_call",
          params: [
            {
              from: checkingResult.account,
              to: contractAddress,
              data: abiInterface.encodeFunctionData("isWhitelisted", [checkingResult.account.toString()])
            },
            "latest"
          ]
        })
      ).includes("1");

      checkingResult.isAllowMint = true;
      checkingResult.isWhitelisted = whitelisted;
      checkingResult.errorMsg = "";
    } catch (e) {
      toast.error("無法取得您是否具有白名單資格！");
      checkingResult.errorMsg = "無法取得您是否具有白名單資格！";
      setEthereumState(checkingResult);
      console.error(e);
      return;
    }

    if (checkingResult.isWhitelisted) {
      try {
        let result = await window.ethereum.request({
          method: "eth_call",
          params: [
            {
              from: checkingResult.account,
              to: contractAddress,
              data: abiInterface.encodeFunctionData("getWhitelistedBuyTotalByAddress", [checkingResult.account.toString()])
            },
            "latest"
          ]
        });

        result = abiInterface.decodeFunctionResult("getWhitelistedBuyTotalByAddress", result)[0].toNumber();
        checkingResult.maximumMintOfWhitelist = 5 - result;
      } catch (e) {
        toast.error("無法取的您的白單 MINT 上限！");
        checkingResult.errorMsg = "無法取的您的白單 MINT 上限！";
        setEthereumState(checkingResult);
        console.error(e);
        return;
      }
    }

    if (!mint) {
      updateMaximumMintOfWhitelist(checkingResult.maximumMintOfWhitelist);
      setEthereumState(checkingResult);
      return;
    }

    let totalPriceOfEther = utils.parseUnits(totalPrice.toString());
    try {
      let balance = await window.ethereum.request({
        method: "eth_getBalance",
        params: [checkingResult.account, "latest"]
      });

      balance = BigNumber.from(balance);
      if (balance.lt(totalPriceOfEther)) {
        toast.error("您的錢包餘額不足！");
        return;
      }
    } catch (e) {
      toast.error("無法取得您的錢包餘額！");
      console.error(e);
      return;
    }

    try {
      let txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: checkingResult.account,
            to: contractAddress,
            value: totalPriceOfEther.toHexString(),
            data: abiInterface.encodeFunctionData("mintCandyMan", [amountOfNormal, amountOfWhitelist])
          }
        ]
      });
      toast.success("感謝購買！交易已送出！");
      setTxHash(txHash);
    } catch (e) {
      toast.error("交易已被取消！");
      console.error(e);
    }
  };

  useEffect(() => {
    if (!window.ethereum) {
      toast.error("請安裝 MetaMask！");
    } else {
      process(false).catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (!window.ethereum) return;

    const handleChainChanged = async (chainId) => {
      process(false.false).catch((error) => console.error(error));
    };

    window.ethereum.on("chainChanged", handleChainChanged);
    return () => window.ethereum.removeListener("chainChanged", handleChainChanged);
  }, [ethereumState]);

  useEffect(() => {
    if (!window.ethereum) return;

    const handleAccountChanged = (accounts) => {
      process(false, false).catch((error) => console.error(error));
    };

    window.ethereum.on("accountsChanged", handleAccountChanged);
    return () => window.ethereum.removeListener("accountsChanged", handleAccountChanged);
  }, [ethereumState]);

  const buttonHandle = async () => {
    if (!ethereumState.isWalletConnected) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        process(false).catch((error) => console.error(error));
      } catch (e) {
        toast.error("請連接錢包！");
        console.error(e);
      }
    } else if (!ethereumState.isAllowMint) {
      toast.error(ethereumState.errorMsg);
    } else {
      let totalAmount = amountOfWhitelist + amountOfNormal;
      if (totalAmount === 0) return;
      if (totalAmount > 20) {
        toast.error("單次 MINT 總和上限 20 隻！");
        return;
      }

      process(true).catch((error) => {
        console.error(error);
      });
    }
  };

  if (!txHash) {
    return (
      <button
        onClick={buttonHandle}
        className="rounded-lg bg-white/80 p-5 font-jf-openhuninn text-2xl shadow-md shadow-theme-red-shadow/70 md:p-2 lg:col-span-2 lg:p-5
                                           lg:transition lg:hover:bg-black/80 lg:hover:text-white lg:hover:outline lg:hover:outline-1 lg:hover:outline-white"
      >
        {ethereumState.isWalletConnected ? (
          <>
            花費 <samp className="font-share-techmono">{totalPrice.toFixed(2)} ETH</samp> MINT
          </>
        ) : (
          <>連接錢包</>
        )}
      </button>
    );
  } else {
    return (
      <button
        onClick={() => window.open(`https://rinkeby.etherscan.io/tx/${txHash}`, "_blank").focus()}
        className="group relative
                rounded-lg bg-white/80 p-5 font-jf-openhuninn text-2xl shadow-md shadow-theme-red-shadow/70 md:p-2 lg:col-span-2 lg:p-5
                                           lg:transition lg:hover:bg-black/80 lg:hover:text-white lg:hover:outline lg:hover:outline-1 lg:hover:outline-white"
      >
        <OpenLinkIcon className="absolute inline-block h-6 w-6 lg:group-hover:fill-white top-2 right-2" />
        <p className="hidden lg:block">感謝您的購買！在 Etherscan 上察看</p>
        <div className="lg:hidden">
          <p>感謝您的購買！</p>
          <p>在 Etherscan 上察看</p>
        </div>
      </button>
    );
  }
};
