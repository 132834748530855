import React, {useEffect, useState} from "react";

import LogoIcon from "../../../res/imgs/logo/candy_man/logo_icon.svg";
import LogoWord from "../../../res/imgs/logo/candy_man/logo_word.svg";
import OpenSeaLogo from "../../../res/imgs/logo/opensea/opensea_2.svg";
import DiscordLogo from "../../../res/imgs/logo/discord/discord_2.svg";
import InstagramLogo from "../../../res/imgs/logo/instagram/instagram.png";

export default () => {
    return (
        <>
            <div className="w-screen border-y-[1px] bg-theme-black">
                <div className="mx-auto grid max-w-screen-lg grid-cols-1 grid-rows-2 place-items-center gap-10 p-9 text-white md:grid-cols-2 md:grid-rows-1 md:gap-0">
                    <div className="flex flex-col items-center justify-center gap-3">
                        <LogoIcon className="h-24"/>
                        <LogoWord className="h-24"/>
                    </div>

                    <div className="flex min-w-[300px] flex-col text-xl font-semibold tracking-[.1em]">
                        <div className="mb-1 border-b-[5px] pl-2 pb-1">Community</div>

                        <a
                            href="https://discord.gg/zfEZUm9eQX"
                            target="_blank"
                            className="my-2 flex cursor-pointer flex-row items-center pl-4"
                        >
                            <DiscordLogo className="mr-3 inline-block h-10 w-10"/>
                            <p className="inline-block">Discord</p>
                        </a>

                        <a
                            href="https://opensea.io/"
                            className="my-2 flex cursor-pointer flex-row items-center pl-4"
                            target="_blank"
                        >
                            <OpenSeaLogo className="mr-3 inline-block h-10 w-10"/>
                            <p className="inline-block">OpenSea</p>
                        </a>

                        <a
                            href="https://www.instagram.com/candy_man_nft/"
                            target="_blank"
                            className="my-2 flex cursor-pointer flex-row items-center pl-4"
                        >
                            <img
                                src={InstagramLogo}
                                alt=""
                                className="mr-3 inline-block h-10 w-10"
                            />
                            <p className="inline-block">Instagram</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="h-10 w-screen bg-theme-red"/>
        </>
    );
};
