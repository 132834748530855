import React, {useState, useRef, useEffect} from "react";
import {gsap} from "gsap";

import LogoIcon from "../../../res/imgs/logo/candy_man/logo_icon.svg";
import LogoWord from "../../../res/imgs/logo/candy_man/logo_word.svg";
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai";

export default () => {
    const [isOpen, setOpen] = useState(false);
    const menuRef = useRef();

    const disableScroll = () => {
        window.scrollTo(0, 0);
        window.onscroll = function () {
            window.scrollTo(0, 0);
        };
    };

    const enableScroll = () => {
        window.onscroll = function () {
        };
    };

    const clickHandle = () => {
        if (!isOpen) {
            disableScroll();
            setOpen(!isOpen);
        } else {
            gsap.fromTo(
                menuRef.current,
                {opacity: 1, top: 0},
                {
                    opacity: 0,
                    top: -100,
                    duration: 0.3,
                    onComplete: () => {
                        enableScroll();
                        setOpen(!isOpen);
                    },
                }
            );
        }
    };

    useEffect(() => {
        if (isOpen)
            gsap.fromTo(
                menuRef.current,
                {opacity: 0, top: -100},
                {opacity: 1, top: 0, duration: 0.3}
            );
    }, [isOpen]);

    return (
        <div className="relative flex items-center justify-center bg-theme-black px-6 py-4">
            {isOpen && (
                <div
                    ref={menuRef}
                    id="menubox"
                    className="fixed left-0 top-0 flex h-screen w-screen touch-none flex-col items-center justify-center bg-black/90 text-white opacity-0"
                >
                    <a
                        href="https://www.candymanstudio.com/#comp-kvs6faom1"
                        target="_self"
                        className="inline-block p-4 tracking-widest text-white transition hover:text-theme-red"
                    >
                        Resident
                    </a>
                    <a
                        href="https://www.candymanstudio.com/#comp-kzfxubzg"
                        target="_self"
                        className="inline-block p-4 tracking-widest text-white transition hover:text-theme-red"
                    >
                        About
                    </a>
                    <a
                        href="https://www.candymanstudio.com/#comp-kzfy05s2"
                        target="_self"
                        className="inline-block p-4 tracking-widest text-white transition hover:text-theme-red"
                    >
                        Roadmap
                    </a>
                    <a
                        href="#"
                        target="_self"
                        className="inline-block p-4 tracking-widest text-white transition hover:text-theme-red"
                    >
                        Mint
                    </a>
                </div>
            )}

            <div className="absolute left-5">
                {isOpen ? (
                    <AiOutlineClose
                        color="#FFF"
                        className="text-3xl"
                        onClick={clickHandle}
                    />
                ) : (
                    <AiOutlineMenu
                        color="#FFF"
                        className="text-3xl"
                        onClick={clickHandle}
                    />
                )}
            </div>

            <div className="space-x-2">
                <a href="https://www.candymanstudio.com/" target="_self">
                    <LogoIcon className="inline-block h-14"/>
                </a>
                <a href="https://www.candymanstudio.com/" target="_self">
                    <LogoWord className="inline-block h-14"/>
                </a>
            </div>
        </div>
    );
};
